import React, { useState } from 'react'
// import { Tooltip } from 'react-tooltip';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage, useIntl } from 'react-intl';
import css from '../../containers/ListingPage/ListingPage.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ShareButton = props => {
	const {isOwnListing, updateEmailModalValue} = props;
	const isPrivateListing = props?.publicData?.isPrivateListing;
	const [copyLink, copyLinkState] = useState(false);
	const rootUrl = process.env.REACT_APP_MARKETPLACE_ROOT_URL;
	const history = useHistory();
	const url = rootUrl + history.location.pathname;

	const copyListingBtnOnClick = () => {
		copyLinkState(true);
		setTimeout(() => copyLinkState(false), 2000);
	}


	const handleEmailPopup = () => {
		updateEmailModalValue(true);
	}

	const intl = useIntl();
	const shareListingToolTip = intl.formatMessage({ id: 'OrderPanel.shareListingToolTip',});
	const copyListingPageToolTip = intl.formatMessage({ id: 'OrderPanel.copyListingPageToolTip',});

	return (
		<>
			<div>
				<div className={css.copy_btn_right}>
					<div className={css.copy_listing}>
						<CopyToClipboard text={url}>
							<div className={css.listingBtnAndIcon}>
								<button className={css.copyListingBtn} onClick={isPrivateListing ? handleEmailPopup : copyListingBtnOnClick} data-tooltip-id="shareListingBtnToolTip" data-tooltip-content={shareListingToolTip}>
									<div>
										<span className={css.copyListingTitle}>
											{copyLink ? <FormattedMessage id="OrderPanel.copyListingCopiedMessage" />
												: <FormattedMessage id="OrderPanel.copyListingButtonMessage" />
											}
										</span>
									</div>
								</button>
								<tooltip id="shareListingBtnToolTip" />
							</div>
						</CopyToClipboard>
					</div>
				</div>
			</div>
		</>
	)
}

export default ShareButton